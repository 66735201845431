.forgotPasswordLink {
  color: #808080 !important;
}
.newsletter {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .newsletter {
    margin: 16px 8px 24px 0;
  }
}

@media only screen and (min-width: 768px) {
  .newsletter {
    margin: 16px 8px 32px 4px;
  }
}

@media only screen and (max-width: 899px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 25.625px;
    width: 25.625px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 36.515625px !important;
    width: 36.515625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 30.75px;
    width: 30.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 36.515625px;
    width: 36.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 41px;
    width: 41px;
  }
}

.newsletterCheckbox {
  border-radius: 50% !important;
  color: #5aa5ef !important;
}

.newsletterText {
  color: #eee;
  cursor: pointer;
  line-height: 1.25;
  margin-bottom: 0;
}

@media only screen and (max-width: 899px) {
  .newsletterText {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .newsletterText {
    font-size: 13.5px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .newsletterText {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .newsletterText {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .newsletterText {
    font-size: 18px;
  }
}

.termsContainer {
  background-color: #31343e;
}

@media only screen and (max-width: 899px) {
  .termsContainer {
    border-radius: 6.25px;
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .termsContainer {
    border-radius: 6.25px !important;
    padding: 10px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .termsContainer {
    border-radius: 7.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .termsContainer {
    border-radius: 8.90625px;
    padding: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .termsContainer {
    border-radius: 10px;
    padding: 16px;
  }
}

.terms {
  color: #eee;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.8;
}

@media only screen and (max-width: 899px) {
  .terms {
    font-size: 11px;
    line-height: 1.5 !important;
    margin-bottom: 27.2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .terms {
    margin-bottom: 13.6px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .terms {
    font-size: 12px;
    margin-bottom: 25.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .terms {
    font-size: 14.25px;
    margin-bottom: 30.28125px;
    padding: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .terms {
    font-size: 16px;
    margin-bottom: 34px;
    padding: 16px;
  }
}

.nameFields {
  display: flex;
}

.nameFields > *:first-child {
  margin-right: 10px;
}

.backLink {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

@media all and (hover: hover) {
  .backLink:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .backLink:active, .backLink:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .backLink {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .backLink {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .backLink {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .backLink {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .backLink {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .backLink {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
}

.reCaptchaContainer {
  /* display: flex;
  justify-content: center; */
  margin-bottom: 40px;
  margin-left: 18px;
}
.signInForm{
  margin-bottom: 115px;
}

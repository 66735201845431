.app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.autoSignInSpinnerContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: radial-gradient(circle at center, #1f2330, #07080c);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.changingTheme *,
.changingTheme *::before,
.changingTheme *::after {
  -webkit-transition-property: none !important;
  transition-property: none !important;
}

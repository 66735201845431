.header {
  /* background-color: #fff;
  color: #000; */
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.headerTheme {
  background-color: #dddddd;
}

.header,
.header * {
  font-family: 'Poppins', sans-serif !important;
}

.header.noHeader {
  display: none !important;
}

@media only screen and (max-width: 899px) {
  .header.transparentCompactView {
    /* background-color: transparent; */
    color: #fff !important;
  }
  .header.transparentCompactView.pageScrolled {
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
  }
  .header.transparentCompactView.pageScrolled:not(.glassCompactHeaderBG) {
    background-color: #1f2330;
  }
  .header.transparentCompactView.pageScrolled.glassCompactHeaderBG {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .header:not(.transparentCompactView) {
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
  }
  .alignButton {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 900px) {
  .header {
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px 0;
  }
  .header.noRegularHeader {
    display: none !important;
  }
  .alignButton {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 899px) {
  .header,
  .headerFill {
    height: 64px;
  }
  html {
    --header-height: 64px;
  }
  .videoBannerHeader {
    height: 0 !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header,
  .headerFill {
    height: 69.375px;
  }
  html {
    --header-height: 69.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header,
  .headerFill {
    height: 83.25px;
  }
  html {
    --header-height: 83.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header,
  .headerFill {
    height: 98.859375px;
  }
  html {
    --header-height: 98.859375px;
  }
}

@media only screen and (min-width: 1710px) {
  .header,
  .headerFill {
    height: 111px;
  }
  html {
    --header-height: 111px;
  }
}

@media only screen and (max-width: 899px) {
  .regularView {
    display: none !important;
  }
}

@media only screen and (min-width: 900px) {
  .compactView {
    display: none !important;
  }
}

.headerContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .headerContent {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .headerContent {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .headerContent {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .headerContent {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .headerContent {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .headerContent {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .headerContent {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .headerContent {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .headerContent {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .headerContent {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .headerContent {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .headerContent {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .headerContent {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .headerContent {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .headerContent {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .headerContent {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .headerContent {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .headerContent {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .headerContent {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .headerContent {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .headerContent {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .headerContent {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .headerContent {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .headerContent {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .headerContent {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .headerContent {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .headerContent {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .headerContent {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .headerContent {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .headerContent {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .headerContent {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .headerContent {
      padding-right: 40px;
    }
  }
}

.options {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menuButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
}

.menuIcon {
  color: #000;
  height: 36px;
  width: 36px;
}

.uploadButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}

.uploadButton.compactView {
  margin-right: 24px;
  padding: 0;
}

.uploadButton.compactView .uploadIcon {
  height: 24px;
}

.uploadButton.regularView {
  border: 1px solid #707070;
}

.uploadButton.regularView .uploadIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
}

.profileMenuContainer, .notificationAnimation {
  animation: growOut 700ms ease-in-out forwards;
  transform-origin: top center
}

@keyframes growOut {
  0% {
     transform: scale(0)
 }
 80% {
     transform: scale(1.1)
 }
 100% {
     transform: scale(1)
 }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .profileMenuContainer {
    padding: 12.75px 25.5px 25.5px 25.5px;
    right: 12.75px !important;
    top: 12.75px !important;
    width: 286.5px !important;
  }
  .profileMenuCurrentProfileDetailsIcon {
    height: 38.085px;
    margin-left: 12.7px;
    width: 38.085px;
  }
  .profileMenuDivider {
    margin: 12.75px 0 25.5px 0;
  }
  .profileMenuProfilesContainer {
    margin-bottom: 36px;
  }
  .profileMenuProfile {
    margin: 18px 0;
  }
  .profileMenuProfileIcon {
    height: 29.535px;
    width: 29.535px;
  }
  .profileMenuLink {
    margin: 24px 0;
  }
  .profileMenuProfileName {
    margin-left: 27px;
  }
  .profileMenuProfileName,
  .profileMenuCurrentProfileDetailsText,
  .profileMenuLink {
    font-size: 17.25px;
  }
  .dropdownIcon {
    font-size: 16.5px;
    height: 21px;
    line-height: 1.2;
    margin-right: 6px;
    width: 21px;
  }
  .profileButton.regularView {
    height: 41.25px;
  }
  .profileButton.regularView .profileIcon {
    height: 41.25px;
    width: 41.25px;
  }
  .uploadButton.regularView {
    height: 41.25px;
    margin-right: 35.1675px;
    padding: 0px 16.25px;
  }
  .uploadButton.regularView .uploadIcon {
    border-radius: 2.25px;
    font-size: 18px;
    height: 18px;
    margin-left: 12px;
    width: 18px;
  }
  .alignIcon {
    margin-right: 15px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profileMenuContainer {
    padding: 15.1406px 30.28125px 30.28125px 30.28125px;
    right: 15.140625px !important;
    top: 15.140625px !important;
    width: 340.21875px !important;
  }
  .profileMenuCurrentProfileDetailsIcon {
    height: 45.2259375px;
    margin-left: 15.1406px;
    width: 45.2259375px;
  }
  .profileMenuDivider {
    margin: 15.1406px 0 30.28125px 0;
  }
  .profileMenuProfilesContainer {
    margin-bottom: 42.75px;
  }
  .profileMenuProfile {
    margin: 21.375px 0;
  }
  .profileMenuProfileIcon {
    height: 35.0728125px;
    width: 35.0728125px;
  }
  .profileMenuLink {
    margin: 28.5px 0;
  }
  .profileMenuProfileName {
    margin-left: 32.0625px;
  }
  .profileMenuProfileName,
  .profileMenuCurrentProfileDetailsText,
  .profileMenuLink {
    font-size: 20.484375px;
  }
  .dropdownIcon {
    font-size: 19.59375px;
    height: 24.9375px;
    line-height: 1.275;
    margin-right: 7.125px;
    width: 24.9375px;
  }
  .profileButton.regularView {
    height: 48.98px;
  }
  .profileButton.regularView .profileIcon {
    height: 48.98px;
    width: 48.98px;
  }
  .uploadButton.regularView {
    height: 48.984375px;
    margin-right: 41.76140625px;
    padding: 0 24.046875px;
  }
  .uploadButton.regularView .uploadIcon {
    border-radius: 2.671875px;
    font-size: 21.375px;
    height: 21.375px;
    margin-left: 14.25px;
    width: 21.375px;
  }
  .uploadText {
    font-size: 17px;
  }
  .alignIcon {
    margin-right: 30px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .profileMenuContainer {
    padding: 17px 34px 34px 34px;
    right: 17px !important;
    top: 17px !important;
    width: 382px !important;
  }
  .profileMenuCurrentProfileDetailsIcon {
    height: 50.78px;
    margin-left: 17px;
    width: 50.78px;
  }
  .profileMenuDivider {
    margin: 17px 0 34px 0;
  }
  .profileMenuProfilesContainer {
    margin-bottom: 48px;
  }
  .profileMenuProfile {
    margin: 24px 0;
  }
  .profileMenuProfileIcon {
    height: 39.38px;
    width: 39.38px;
  }
  .profileMenuLink {
    margin: 32px 0;
  }
  .profileMenuProfileName {
    margin-left: 36px;
  }
  .profileMenuProfileName,
  .profileMenuCurrentProfileDetailsText,
  .profileMenuLink {
    font-size: 23px;
  }
  .dropdownIcon {
    font-size: 22px;
    height: 28px;
    line-height: 1.35;
    margin-right: 8px;
    width: 28px;
  }
  .profileButton.regularView {
    height: 55px;
  }
  .profileButton.regularView .profileIcon {
    height: 55px;
    width: 55px;
  }
  .uploadButton.regularView {
    height: 55px;
    margin-right: 46.89px;
    padding: 0 27px;
  }
  .uploadButton.regularView .uploadIcon {
    border-radius: 3px;
    font-size: 24px;
    height: 24px;
    margin-left: 16px;
    width: 24px;
  }
  .uploadText {
    font-size: 18px;
  }
}

.uploadText {
  color: #000000;
  font-weight: 400;
}

.profileButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
}

.profileButton.compactView {
  border: 1px solid #fff;
  border-radius: 99999999999999999999px;
  height: 32px;
  width: 32px;
  overflow: hidden;
  padding: 0;
}

.profileButton.compactView .profileMenuCurrentProfileDetailsIcon {
  margin-left: 0;
  width: 100%;
}

.profileButton.compactView .profileIcon {
  height: 22px;
}

@media only screen and (min-width: 900px) {
  .profileBar {
    display: none !important;
  }
}

.profileBarContent {
  max-width: 420px;
  width: calc(100vw - 32px);
}

.profileBarProfilesContainer {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  position: relative;
  width: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.profileBarProfilesContainer::-webkit-scrollbar {
  display: none;
}

.profileBarProfilesContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  .profileBarProfilesContent {
    padding: max(25px, env(safe-area-inset-top));
  }
}

@supports not (padding: max(0px)) {
  .profileBarProfilesContent {
    padding: 25px;
  }
}

@supports (padding: max(0px)) {
  .profileBarProfilesContent {
    padding: max(25px, env(safe-area-inset-right));
  }
}

@supports not (padding: max(0px)) {
  .profileBarProfilesContent {
    padding: 25px;
  }
}

@supports (padding: max(0px)) {
  .profileBarProfilesContent {
    padding: max(25px, env(safe-area-inset-left));
  }
}

@supports not (padding: max(0px)) {
  .profileBarProfilesContent {
    padding: 25px;
  }
}

.profileBarProfile {
  color: #fff;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 11px;
  width: 97px;
}

.profileBarProfile:last-of-type {
  margin-right: 0;
}

.profileBarProfileIconContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #efefef;
  border-radius: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 97px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.profileBarProfileIcon {
  color: #fff;
  height: 50.2px;
  width: 50.2px;
}

.profileBarProfileAddIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 32px;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 36px;
  height: 44px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  width: 44px;
}

.profileBarProfileName {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
}

.profileBarLinksContainer {
  padding: 0 35px 35px 35px;
}

.profileBarLink {
  color: #ffffff;
  display: block;
  font-size: 15px;
  font-weight: 500;
  margin: 28px 0;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .profileMenu {
    display: none !important;
  }
}

.profileMenu {
  padding: 0 !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileMenu {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileMenu {
      margin-right: -1px;
    }
  }
}

.profileMenuCurrentProfileDetails {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.profileMenuCurrentProfileDetailsIcon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.profileMenuCurrentProfileDetailsIcon.border {
  border-radius: 9999999999999999px;
  border: 1px solid;
}
.profileMenuCurrentProfileDetailsIcon.border2 {
  border-radius: 9999999999999999px;
   border: 1px solid #707070;
}
.profileMenuDivider {
  background-color: #000;
  border: none;
  height: 1px;
}

.profileMenuLinksContainer {
  position: relative;
}

.profileMenuProfile {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profileMenuProfileIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -o-object-fit: contain;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: relative;
}
.profileMenuProfileIcon.border {
  border: 1px solid black;
  border-radius: 999999999999999999999999px;
}
.profileMenuProfileIcon.border2 {
  border: 2px solid black;
  border-radius: 999999999999999999999999px;
}
.profileMenuProfileName {
  color: #000;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profileMenuLink {
  color: #000;
  display: block;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profileMenuLink:last-of-type {
  margin-bottom: 10px;
}

@media only screen and (max-width: 899px) {
  .transparentCompactView .menuIcon {
    color: #fff !important;
  }
  .transparentCompactView .uploadButton.compactView,
  .transparentCompactView .profileButton.compactView {
    border-color: #fff !important;
    border-width: 2px;
  }
  .transparentCompactView .uploadButton.compactView .uploadIcon,
  .transparentCompactView .uploadButton.compactView .profileIcon,
  .transparentCompactView .profileButton.compactView .uploadIcon,
  .transparentCompactView .profileButton.compactView .profileIcon, 
  .uploadButton.compactView .uploadIcon {
    color: #fff !important;
  }
}

.profileIcon {
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  padding: 6%;
}

.dropdownIcon {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}

.headerFill {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.headerFill.noHeader {
  display: none !important;
}

@media only screen and (min-width: 900px) {
  .headerFill.noRegularHeader {
    display: none !important;
  }
}

@media all and (hover: hover) {
  .profileBarLink:hover,
  .profileMenuLink:hover,
  .profileMenuProfile:hover {
    text-decoration: underline;
  }
  .fontBold:hover {
    font-weight: 700;
  }
}

@media all and (pointer: coarse) {
  .profileBarLink:active,
  .profileBarLink:target,
  .profileMenuLink:active,
  .profileMenuLink:target,
  .profileMenuProfile:active,
  .profileMenuProfile:target {
    text-decoration: underline;
  }
}

.profileHeader {
  z-index: 100;
  position: relative;
}

.profileHeader.hidden {
  display: none !important;
}

.header.hidden {
  display: none !important;
}

.profileHeader,
.profileHeaderContent {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.profileHeader,
.profileHeader *,
.profileHeaderContent,
.profileHeaderContent * {
  font-weight: 600;
}

@media only screen and (max-width: 899px) {
  .profileHeader,
  .profileHeaderContent {
    display: none !important;
  }
}

.profileHeaderContent {
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-left: max(82.5px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-left: max(97.96875px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-left: max(110px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-left: 82.5px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-left: 97.96875px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-left: 110px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-right: max(82.5px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-right: max(97.96875px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-right: max(110px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profileHeaderContent {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profileHeaderContent {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profileHeaderContent {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profileHeaderContent {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profileHeaderContent {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profileHeaderContent {
      padding-right: 82.5px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profileHeaderContent {
      padding-right: 97.96875px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .profileHeaderContent {
      padding-right: 110px;
    }
  }
}

.namePosition {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  max-width: 10ch;
}

.leftContent,
.rightContent,
.profileHeaderIconButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.profileHeaderIconButton {
  color: #ffffff;
}

.profileHeaderIconButton:last-of-type {
  margin-right: 0;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profileHeaderIconButton {
    margin-right: 65px;
  }
  .namePosition {
    margin-right: 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profileHeaderIconButton {
    margin-right: 78px;
  }
  .namePosition {
    margin-right: 78px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profileHeaderIconButton {
    margin-right: 92.625px;
  }
  .namePosition {
    margin-right: 92.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .profileHeaderIconButton {
    margin-right: 104px;
  }
  .namePosition {
    margin-right: 104px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profileHeaderIconButtonIcon {
    height: 27.1875px;
    margin-right: 11.875px;
    width: 27.1875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profileHeaderIconButtonIcon {
    height: 32.625px;
    margin-right: 14.25px;
    width: 32.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profileHeaderIconButtonIcon {
    height: 38.7421875px;
    margin-right: 16.921875px;
    width: 38.7421875px;
  }
}

@media only screen and (min-width: 1710px) {
  .profileHeaderIconButtonIcon {
    height: 43.5px;
    margin-right: 19px;
    width: 43.5px;
  }
}

.profileHeaderIconButtonText {
  text-transform: uppercase;
  color: #fff;
}

.profileHeaderIconButtonText:hover, .uploadText:hover {
  color: #5aa5ef;
}


.uploadButton:hover > .uploadText {
  color: #000 !important;
}

.textMouseOver, .profileMouseOver {
  color: #5aa5ef;
}

.uploadButtonHover:hover {
  background-color: #5aa5ef !important;
}

.notificationIconHover:hover, .profileMenuCurrentProfileDetailsIcon:hover {
  color: #5aa5ef;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profileHeaderIconButtonText {
    font-size: 11.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profileHeaderIconButtonText {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profileHeaderIconButtonText {
    font-size: 16.921875px;
  }
}

@media only screen and (min-width: 1710px) {
  .profileHeaderIconButtonText {
    font-size: 19px;
  }
}
.backButton {
  position: absolute;
  /* top: 0;
  left: 0; */
  transform: translateX(-60px);
}

.notificationIcon {
  color: white;
  margin-left: -26px;
}

.videoUploadIcon {
  color: white;
  /* margin-left: -26px; */
}

.myProjectorLink {
  color: #fff;
}

.profileIconPosition {
  margin-right: 25px;
}

.iconColor {
  color: #1172D2;
  margin-left: 6px
}

.allProjectorAlign {
  margin-left: 38px;
}

.notificationDropDown {
  max-width: 400px;
  min-width: 200px;
  /* height: 800px; */
  /* overflow: auto; */
  /* overflow-x: hidden */
  /* overflow-y: auto;
  max-height: 400px */
}

.notificationIconBlack {
  color: #000;
  margin-left: 16px;
  margin-top: 18px;
}

.notficatonIcon {
  top: 15px;
  position: relative;
  left: 13px;
}

.notfHeading {
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  padding: 15px;
  font-family: 'Montserrat' !important;
}

.dividerAlign {
  margin-left: 18px !important;
  color : #707070 !important;
  /* border-style : solid !important; */
  outline-width: medium !important;
  margin-right: 14px !important;
}

.notificationMenu {
  /* overflow-y: auto;
  max-height: 600px */
  max-height:600px;
  overflow-y:scroll;
  min-width: 500px;
}

.invitationHeading {
  color: #000 !important;
  font-family: 'Montserrat' !important;
  font-size : 18px !important;
  font-weight: 400 !important;
}

.notificationContainer {
  margin: 5px 11px 9px 17px;
}

.clearAllIcon {
  color: #000 !important;
  margin-top: 10px;
  font-size: 12px !important;
  text-transform: none !important;
  font-family: 'Montserrat' !important;
  justify-content: flex-end;
}
.generalMessage {
  font-family: 'Montserrat' !important;
  font-size : 15px !important;
  font-weight: 600 !important;
}
.divSpace {
  margin-top : 10px;
  margin-bottom: 13px
}

.notfDivider {
  margin-left: 3px !important;
  color: #707070 !important;
  outline-width: medium !important;
  margin-right: 7px !important;
}

.loaderDiv {
  justify-content: center;
  display: flex;
}

.clearIcon {
  color: #000 !important;
  margin-top: 10px;
  font-size: 12px !important;
  text-transform: none !important;
  font-family: 'Montserrat' !important;
}

.clearAllIconAlign{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.clearAllNotification {
  display: flex;
}

.headingSpace {
  margin-bottom: 14px !important;
}

@media only screen and (max-width: 384px) {
  .clearAllIcon {
    font-size: 10px !important;
  }
  .clearIcon {
    font-size: 10px !important;
  }
}

.logoImage {
  width: 50%;
  height: 90%;
  cursor: pointer;
}

.successImage {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.errorIcon {
  width: 24px;
  height: 24px;
}

.videoIcon {
  color: #d93025;
}

.titleforVideoUpload {
  font-family: 'Montserrat' !important;
  font-size : 15px !important;
  font-weight: 600 !important;
  padding: 6px !important;
}
.remainingtimeforVideoUpload {
  display: grid;
  justify-content: right;
  font-family: 'Montserrat' !important;
  font-size : 12px !important;
  font-weight: 500 !important;
  padding: 11px !important;
  align-items: center;
}

.cancelIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.circularProgressBar {
  animation: none !important;
}

.videoBannerContainer {
  width: 100%;
  height: 450px;
}
.heroImage {
  width: 100%;
  height: 450px;
  position: relative;
}

.videoBannerHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.videoDetails {
  display: flex;
  bottom: 0;
  width: 100%;
  color: #fff;
  position: absolute;
  padding-left: 100px;
  flex-direction: column;
  height: 150px;
}

.videoActions {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .videoActions {
    margin-bottom: 12px;
  }
  .videoDetails {
    padding-left: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoActions {
    margin-bottom: 12px;
  }
  .videoDetails {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoActions {
    margin-bottom: 12px;
  }
  .videoDetails {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoActions {
    margin-bottom: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoActions {
    margin-bottom: 16.921875px;
  }
  .videoBannerContainer {
    height: 500px;
  }
  .heroImage {
    height: 530px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoActions {
    margin-bottom: 19px;
  }
  .videoBannerContainer {
    height: 530px;
  }
  .heroImage {
    height: 550px;
  }
}

.videoPlayButton {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 600;
  position: relative;
  text-shadow: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .videoPlayButton {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoPlayButton {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoPlayButton {
    font-size: 16.25px;
    height: 56px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoPlayButton {
    font-size: 19.5px;
    height: 55.5px;
    padding: 0 34.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoPlayButton {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoPlayButton {
    font-size: 26px;
    height: 68px;
    padding: 0 46px;
  }
}


@media only screen and (max-width: 767px) {
  .videoPlayIcon {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoPlayIcon {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoPlayIcon {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoPlayIcon {
    height: 27px;
    margin-right: 17.25px;
    width: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoPlayIcon {
    height: 32.0625px;
    margin-right: 20.48438px;
    width: 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoPlayIcon {
    height: 36px;
    margin-right: 23px;
    width: 36px;
  }
}

.videoTitle {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  /* max-width: 17ch; */
  /* white-space: nowrap; */
  line-height: 1em;
  height: 2em;
  /* color: #fff; */
  color: #fff;
  /* width: 60%; */
  top: 45%;
  position: absolute;
  left: 100px;
}

@media only screen and (max-width: 899px) {
  .videoTitle {
    margin-bottom: auto;
    font-size: 22.400000000000002px;
  }
}

@media only screen and (max-width: 767px) {
  .videoTitle {
    font-size: 22.400000000000002px;
    left: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoTitle {
    font-size: 22.937500000000004px;
    left: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoTitle {
    font-size: 22.25px;
    /* margin-right: 45%; */
    left: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoTitle {
    font-size: 26.50000000000001px;
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoTitle {
    font-size: 33.78125000000001px;
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1710px) {
  .videoTitle {
    font-size: 35px;
    margin-right: 50%;
  }
}

.videoAddButton {
  position: relative;
}

.videoAddButton {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  border-radius: 50%;
  border-style: solid;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 767px) {
  .videoAddButton {
    border-width: 2px;
    font-size: 22.4px;
    height: 56px;
    margin-left: 11.2px;
    width: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoAddButton {
    border-width: 2px;
    font-size: 26.25px;
    height: 56px;
    margin-left: 13.125px;
    width: 56px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoAddButton {
    border-width: 3px;
    font-size: 35px;
    height: 56px;
    margin-left: 17.5px;
    width: 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoAddButton {
    border-width: 3px;
    font-size: 42px;
    height: 55px;
    margin-left: 21px;
    width: 55px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoAddButton {
    border-width: 4px;
    font-size: 49.875px;
    height: 56.125px;
    margin-left: 24.9375px;
    width: 56.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoAddButton {
    border-width: 4px;
    font-size: 56px;
    height: 68px;
    margin-left: 28px;
    width: 68px;
  }
}

.videoInfoWrap {
  display: block;
}

.videoInfo {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .videoInfo {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoInfo {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoInfo {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoInfo {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoInfo {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoInfo {
    font-size: 20px;
  }
}


.videoInfoSeparator {
  background-color: #fff;
  border-radius: 4px;
  display: block;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 7.5px;
    width: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 9px;
    width: 2px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoInfoSeparator {
    height: 17.8125px;
    margin: 0 10.6875px;
    width: 3px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoInfoSeparator {
    height: 20px;
    margin: 0 12px;
    width: 3px;
  }
}
.updatePaymentButton {
  background-color: #5AA5EF;
  border: 0.5px solid #707070;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  width: 200px;
  font-size: 14px;
  margin-top: 20px;
  padding: 5px;
  margin-bottom: 15px;
}

.semiOvalWrap {
  display: flex;
  width: 100%;
  justify-content: center;
}

.box {
  height: 50px;
  width: 50%;
  background-image: radial-gradient(ellipse at top center, #073D96 79%, transparent 70%);
  position: absolute;
  top: 0;
  filter: blur(50px);
}

.selectedTab {
  text-decoration: underline;
  text-underline-offset: 5px !important;
  text-decoration-color: #5AA5EF;
  text-decoration-thickness: 3px;
}
.iconMouseOver > path:nth-of-type(2)  {
  fill: #5aa5ef !important;
}

.searchIconMouseOver > g > path:nth-of-type(2)  {
  fill: #5aa5ef !important;
}

.fileUploadButton {
  height: 41px;
  width: 122px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.googleClass{
  /* margin-left: 550px; */
}
.dropboxClass{
  margin-left: 715px;
  margin-right: 0px !important
}
.buttonGoogle{
  background-color:#dddddd !important;
  border: 1px solid #707070 !important;
  color: rgb(10, 10, 10) !important;
  box-shadow: none !important;
}
.googleClass div{
  margin-right: 2px !important;
  background: #dddddd!important;
}
.buttonGoogle span{
  padding: 10px 10px 10px 0px !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  font-family: 'Poppins' !important;
}
.dropboxIcon{
  height: 25px;
  width: 25px;
  margin-right: 6px;
}
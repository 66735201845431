.subHeadingContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.filterText {
  color: #717171;
}
.filterWrap{
  text-align: right;
}

.subHeading {
  font-size: 20px;
  color: #afb2bf;
  font-weight: 600;
  margin-top: -11px;
}
.groupContainer {
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  max-height: 200px;
  overflow-y: auto;
}

.group {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  line-height: 1.8;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
}

@media all and (hover: hover) {
  .group:not(.addGroup):hover {
    color: #5aa5ef !important;
  }
}

@media all and (pointer: coarse) {
  .group:not(.addGroup):active, .group:not(.addGroup):target {
    color: #5aa5ef !important;
  }
}

.group.addGroup {
  color: #5aa5ef;
}

@media only screen and (max-width: 767px) {
  .group {
    font-size: 15px;
  }
  .groupContainer {
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 6px #00000029;
            box-shadow: 0px 3px 6px #00000029;
    padding: 23px;
  }
  .visibilityTitle {
    font-size: 20.03125px;
    margin-top: 12px;
  }
  .visibilityDescription {
    font-size: 14px;
  }
  .visibilityContainer {
    -webkit-box-shadow: none;
            box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .visibilityContainer :global(.MuiTypography-root) {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .group {
    font-size: 15px;
  }
  .groupContainer {
    padding: 17.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .group {
    font-size: 15px;
  }
  .groupContainer {
    padding: 17.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .group {
    font-size: 15px;
  }
  .groupContainer {
    padding: 17.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .group {
    font-size: 17.8125px;
  }
  .groupContainer {
    padding: 20.484375px;
  }
}

@media only screen and (min-width: 1710px) {
  .group {
    font-size: 20px;
  }
  .groupContainer {
    padding: 23px;
  }
}

* {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-display: none;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  -webkit-font-kerning: auto;
          font-kerning: auto;
  -webkit-font-language-override: normal;
          font-language-override: normal;
  font-optical-sizing: auto;
  font-size-adjust: none;
  font-stretch: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
}

body {
  font-family: 'Poppins';
  margin: 0;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

:root {
  overflow-x: hidden;
}

@media only screen and (max-width: 319px) {
  :root {
    --site-padding: 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  :root {
    --site-padding: 16px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  :root {
    --site-padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  :root {
    --site-padding: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  :root {
    --site-padding: 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  :root {
    --site-padding: 32px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  :root {
    --site-padding: 36px;
  }
}

@media only screen and (min-width: 1710px) {
  :root {
    --site-padding: 40px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 12px);
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 24px);
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 28px);
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 32px);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 36px);
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 40px);
    }
  }
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 12px);
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 16px);
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 20px);
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 24px);
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 28px);
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 32px);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 36px);
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 40px);
    }
  }
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 12px);
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 16px);
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 20px);
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 24px);
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 28px);
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 32px);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 36px);
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 40px);
    }
  }
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 12px);
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 16px);
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 20px);
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 24px);
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 28px);
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 32px);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 36px);
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 40px);
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-bottom: 40px;
    }
  }
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-left: 40px;
    }
  }
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-top: 40px;
    }
  }
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-right: 40px;
    }
  }
}

.image-item {
  /* padding: 20px 0 20px 0px; */
  width: 310px !important;
  margin-right: 27px;
  transform-style: initial;
}

.category-item {
  width: 120px !important;
  margin-right: 27px;
}

.react-multi-carousel-track {
  transform-style: initial;
}

.profile-item {
  width: 181px !important;
}

.carousel-container {
  height: 270px;
}

.custom-dot-list-style {
  margin-bottom: 255px;
  justify-content: flex-end;
}

.react-multi-carousel-dot--active button {
  background: #1172D2 !important;
}

.custom_arrow {
  background: transparent !important;
  color: white;
  margin-bottom: 37px;
  width: 20px;
  height: 20px;
}

.custom_arrow_profile {
  background: transparent !important;
  color: white;
  margin-bottom: 13px;
  width: 20px;
  height: 20px;
}

.custom_arrow:hover, .custom_arrow_category:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
  z-index: 1000 !important;
}

.custom_arrow_category {
  margin-bottom: -6px;
  width: 20px;
  height: 20px;
}

.layout_arrow_category {
  margin-bottom: -6px;
  width: 10px;
  height: 10px;
  min-width: 25px;
  min-height: 25px;
}

.layout_arrow_category:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
  z-index: 1000 !important;
}

/* .react-multiple-carousel__arrow:hover {
  background: transparent;
} */
.slide-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 60vh;
}

.album-image-item {
  width: 211px !important;
  margin-right: 27px;
}

.react-multiple-carousel__arrow_photo { 
  min-width: 42px;
  min-height: 113px;
  border-radius: 0px !important;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(255, 0, 0, 0) !important;
}

/* .react-multiple-carousel__arrow, .react-multiple-carousel__arrow_photo { */
  /* min-width: 42px;
  min-height: 113px;
  border-radius: 0px; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background: rgba(255, 0, 0, 0) !important; */
/* } */

.custom_arrow_photolist {
  background: #707070;
  color: white;
  margin-bottom: 7px;
  min-height: 81px;
  border-radius: 0px !important;
  min-width: 30px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  min-height: 100vh;
  /* max-height: calc(100vh - 0px); */
  
}

.image-gallery-thumbnails-wrapper {
  bottom: 84px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100%;
  cursor: pointer;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 65px;
  padding-right: 6px;
  padding-left: 6px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover {
  border: 4px solid transparent;
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2); 
}

.image-gallery-left-nav {
  left: 25%;
}

.image-gallery-right-nav {
  right: 25%;
}

.image-gallery-play-button {
  left: 45%;
  top: 0;
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 100px;
  width: 80px;
}

.carousel-container {
  overflow: visible !important;
}

.image-item-whats-new {
  width: 277px !important;
  margin-right: 22px;
}

/* .carousel-profile-container {
  display: flex;
  justify-content: center
} */

.align-center {
  display: flex;
  justify-content: center;
}

.carousel-container-for-whats-new {
  overflow-x: hidden;
  right: 0;
  overflow-wrap: break-word;
  height: 250px;
}

.carousel-container-album-banner {
  overflow: visible;
}
.carousel-item-albumbanner {
  padding-left: 20px;
  padding-right: 20px;
  height: 300px;
}

@media only screen and (max-width: 319px) {
  .carousel-profile-container {
    width: 192px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .carousel-profile-container {
    width: 343px;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .carousel-profile-container {
    width: 384px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .carousel-profile-container {
    width: 577px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .carousel-profile-container {
    width: 578px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .carousel-profile-container {
    width: 700px;
  }
  .custom_arrow_profile {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .carousel-profile-container {
    width: 910px;
  }
  .custom_arrow_profile {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 1710px) {
  .carousel-profile-container {
    width: 930px;
  }
}

.PhoneInput {
  background-color: #31343e;
  border: 2px solid #31343e;
  color: #ffffff;
  height: 60px;
  /* position: absolute; */
  width: 100%;
  border-radius: 5px;
}

.PhoneInputInput:focus {
  border-color: #6d6f76 !important;
}

.PhoneInputInput::-webkit-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

.PhoneInputInput:-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

.PhoneInputInput::-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

.PhoneInputInput::placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 15px;
  font-weight: 600;
}


.PhoneInputInput {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 15px;
}

.PhoneInputCountry {
  margin-left: 10px;
}

.carousel-container-album-banner {
  overflow: visible;
}
.carousel-item-albumbanner {
  padding-left: 20px;
  padding-right: 20px;
}

.Shimmer-shimmer-1-1-1 {
  /* display: block !important; */
  background-size: 400px 400px !important;
  margin-top: 30px !important;
}
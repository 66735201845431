.navButton {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-transform: capitalize !important;
}

@media all and (hover: hover) {
  .navButton:disabled:hover {
    background-color: transparent !important;
    color: #000 !important;
  }
}

@media all and (pointer: coarse) {
  .navButton:disabled:active,
  .navButton:disabled:target {
    background-color: transparent !important;
    color: #000 !important;
  }
}

@media all and (hover: hover) {
  .navButton:hover {
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .navButton:active,
  .navButton:target {
    color: #fff !important;
  }
}

@media only screen and (max-width: 767px) {
  .navButton {
    color: #fff !important;
    width: calc(50% - 8px);
  }
  .navButton:last-of-type {
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .navButton {
    color: #000 !important;
  }
  .navButton:last-of-type {
    margin-left: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .navButton {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .navButton {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .navButton {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .navButton {
    font-size: 16px !important;
  }
}

.backButton,
.closeButton {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.backButton {
  color: #000;
  cursor: pointer;
  margin-left: -12px;
}

@media only screen and (min-width: 768px) {
  .backButton {
    display: none;
  }
}

.closeButton {
  cursor: pointer;
  margin-left: 2%;
}

@media only screen and (max-width: 767px) {
  .closeButton {
    display: none;
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .closeButton {
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .closeButton {
    height: 18px;
    margin-right: -27px;
    margin-top: -36px;
    width: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .closeButton {
    height: 21.375px;
    margin-right: -32.0625px;
    margin-top: -42.75px;
    width: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .closeButton {
    height: 24px;
    margin-right: -36px;
    margin-top: -48px;
    width: 24px;
  }
}

.header {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 10;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header {
      padding-top: max(
        var(--site-padding),
        env(safe-area-inset-top)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header {
      padding-top: max(
        var(--site-padding),
        env(safe-area-inset-top)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header {
      padding-top: max(
        var(--site-padding),
        env(safe-area-inset-top)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header {
      padding-top: max(19.21875px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header {
      padding-top: max(30.75px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header {
      padding-top: max(30.75px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header {
      padding-top: max(36.51562px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header {
      padding-top: max(41px, env(safe-area-inset-top)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header {
      padding-top: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header {
      padding-top: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header {
      padding-top: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header {
      padding-top: 19.21875px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header {
      padding-top: 36.51562px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header {
      padding-top: 41px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header {
      padding-left: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header {
      padding-right: var(--site-padding) !important;
    }
  }
}

.headerContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .headerContent {
    border-bottom-width: 0px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-bottom: var(--site-padding);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .headerContent {
    border-bottom-width: 2px;
    padding-bottom: 11.5px;
  }
}

.headerText {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .headerText {
    color: #fff;
    font-size: 21px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .headerText {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .headerText {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headerText {
    font-size: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .headerText {
    font-size: 30px;
  }
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 0 !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content {
      padding-bottom: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content {
      padding-left: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content {
      padding-right: var(--site-padding) !important;
    }
  }
}

.content > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.body {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .body {
    width: calc(
      100vw - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    );
  }
}

@media only screen and (min-width: 900px) {
  .body {
    height: calc(
      100vh - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    ) !important;
  }
}

@media only screen and (max-width: 767px) {
  .body {
    height: 100vh !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .body {
    --site-padding: 30.703124999999996px;
    border-radius: 5px;
    height: calc(100vh - (var(--header-height))) !important;
    max-width: 1033.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .body {
    --site-padding: 49.12499999999999px;
    border-radius: 7.5px;
    max-width: 1033.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .body {
    --site-padding: 58.33593749999999px;
    border-radius: 8.90625px;
    max-width: 1227.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .body {
    --site-padding: 65.5px;
    border-radius: 10px;
    max-width: 1378px;
  }
}

@media only screen and (max-height: 568px) {
  .body {
    border-radius: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.footer {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-top: 24px !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-top: max(unset, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-top: max(unset, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-top: max(unset, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-top: max(19.21875px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-top: max(30.75px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-top: max(30.75px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-top: max(36.51562px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-top: max(41px, env(safe-area-inset-top)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-top: unset !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-top: unset !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-top: unset !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-top: 19.21875px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-top: 36.51562px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-top: 41px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-bottom: max(19.21875px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-bottom: max(30.75px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-bottom: max(30.75px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-bottom: max(36.51562px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-bottom: max(41px, env(safe-area-inset-bottom)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-bottom: 32px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-bottom: 32px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-bottom: 32px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-bottom: 19.21875px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-bottom: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-bottom: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-bottom: 36.51562px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-bottom: 41px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-left: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer {
      padding-right: var(--site-padding) !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    display: none;
    padding-top: 24px;
  }
}

.footerContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}

.footerContentForcontent {
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .footerContent {
    margin-left: auto;
    max-width: 360px;
  }
}

.selectedCount {
  font-size: 15px;
  font-weight: 500;
}

.shimmerWrapper {
  width: 100%;
  height: 100vh;
  padding: 30px 0;

}

.line {
  width: 120px;
  height: 20px;
  align-self: center;
  border-radius: 8px
}

.square {
  width: 150px !important;
  height: 120px !important;
  /* align-self: center; */
  border-radius: 8px !important;
  margin-right: 20px;
}

.imageWrap {
  display: block !important;
}

.hiddenFormField {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

@media only screen and (max-width: 767px) {
  .logo {
    margin-bottom: 44px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .logo {
    margin-bottom: 64px;
    margin-top: 24px;
  }
}

.authPage {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: radial-gradient(circle at center, #fafbff, #ffffff);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.authPage,
.authPage * {
  font-family: 'Montserrat' !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPage {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPage {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPage {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPage {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPage {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPage {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPage {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPage {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPage {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPage {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPage {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPage {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPage {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPage {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPage {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPage {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPage {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPage {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPage {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPage {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPage {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPage {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPage {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPage {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPage {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPage {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPage {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPage {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPage {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPage {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPage {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPage {
      padding-bottom: 40px;
    }
  }
}

.authPageContent {
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .authPageContent {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .authPageContent {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .authPageContent {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .authPageContent {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .authPageContent {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .authPageContent {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .authPageContent {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .authPageContent {
      padding-right: 40px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .authPageContent {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .authPageContent {
    max-width: 600px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .authPageContent {
    max-width: 600px;
  }
}

@media only screen and (min-width: 1710px) {
  .authPageContent {
    max-width: 600px;
  }
}

@media only screen and (max-width: 1199px) {
  .suggestionText,
  .suggestionLink {
    font-size: 13px;
    margin-top: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .suggestionText,
  .suggestionLink {
    font-size: 13.5px;
    margin-top: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .suggestionText,
  .suggestionLink {
    font-size: 16.03125px;
    margin-top: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .suggestionText,
  .suggestionLink {
    font-size: 18px;
    margin-top: 18px;
  }
}

.suggestionText {
  color: #ddd;
}

.suggestionLink {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

@media all and (hover: hover) {
  .suggestionLink:hover {
    color: #fff !important;
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .suggestionLink:active, .suggestionLink:target {
    color: #fff !important;
    text-decoration: underline;
  }
}

.recommendationText {
  color: #ccc;
  margin-top: 1em;
  font-weight: 500;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .recommendationText {
    font-size: 15px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .recommendationText {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .recommendationText {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .recommendationText {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .recommendationText {
    font-size: 28px;
  }
}

.passwordStrengthBar {
  background-color: #3d3f47;
  border-radius: 8px;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .passwordStrengthBar {
    height: 4px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .passwordStrengthBar {
    height: 8px !important;
    margin-top: 15.9375px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .passwordStrengthBar {
    height: 8px;
    margin-top: 19.125px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .passwordStrengthBar {
    height: 8.90625px;
    margin-top: 22.7109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .passwordStrengthBar {
    height: 10px;
    margin-top: 25.5px;
  }
}

.passwordStrengthBarContent {
  border-radius: 8px;
  height: 100%;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

.passwordStrengthBarContent.weak {
  background-color: #ff0000;
}

.passwordStrengthBarContent.medium {
  background-color: #5aa5ef;
}

.passwordStrengthBarContent.strong {
  background-color: #5aa5ef;
}

.passwordStrengthNotes {
  color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .passwordStrengthNotes {
    font-size: 9px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .passwordStrengthNotes {
    font-size: 11.25px !important;
    margin-top: 15.9375px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .passwordStrengthNotes {
    font-size: 13.5px;
    margin-top: 19.125px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .passwordStrengthNotes {
    font-size: 16.03125px;
    margin-top: 22.7109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .passwordStrengthNotes {
    font-size: 18px;
    margin-top: 25.5px;
  }
}

.currentPasswordStrength {
  display: none;
  margin-left: 2%;
  text-transform: capitalize;
}

.currentPasswordStrength.weak, .currentPasswordStrength.medium, .currentPasswordStrength.strong {
  display: inline-block !important;
}

.currentPasswordStrength.weak {
  color: #ff0000;
}

.currentPasswordStrength.medium {
  color: #5aa5ef;
}

.currentPasswordStrength.strong {
  color: #5aa5ef;
}

.backText {
  vertical-align: middle;
  font-size: 28px;
  padding: 15px;
  color: #fff;
  font-family: 500;
  cursor: pointer;
}

.videoBackButton {
  cursor: pointer;
  margin-left: -14px;
  position: relative;
  vertical-align: middle;
  width: 50px;
}

.backButtonBlock {
  width: 100%;
  margin-left: 4%;
  position: fixed;
  top: 4%;
}

@media only screen and (max-width: 419px) {
  .backText {
    font-size: 17px;
    padding: 8px;
  }
  .videoBackButton {
    margin-left: 2px;
    width: 27px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .backText {
    font-size: 16px;
    padding: 4px;
  }
  .videoBackButton {
    margin-left: 4px;
    width: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .backText {
    font-size: 22px;
    padding: 9px;
  }
  .videoBackButton {
    margin-left: -4px;
    width: 37px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .backText {
    font-size: 23px;
    padding: 10px;
  }
  .videoBackButton {
    margin-left: -5px;
    width: 40px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .backText {
    font-size: 25px;
    padding: 15px;
  }
  .videoBackButton {
    margin-left: -6px;
    width: 45px;
  }
}

@media only screen and (min-width: 1710px) {
  .backText {
    font-size: 28px;
    padding: 15px;
  }
  .videoBackButton {
    margin-left: -14px;
    width: 50px;
  }
}
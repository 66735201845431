.button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-style: solid;
  color: #fff;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-family: inherit;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.button.blockText {
  font-weight: 600;
}

@media all and (hover: hover) {
  .button.blackColor:hover {
    background-color: #000 !important;
  }
  .button.blackColor:hover.glow {
    -webkit-box-shadow: #000 0 0 32px 0px;
            box-shadow: #000 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button.blackColor:active, .button.blackColor:target {
    background-color: #000 !important;
  }
  .button.blackColor:active.glow, .button.blackColor:target.glow {
    -webkit-box-shadow: #000 0 0 32px 0px;
            box-shadow: #000 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button.blueColor:hover {
    background-color: #5aa5ef !important;
  }
  .button.blueColor:hover.glow {
    -webkit-box-shadow: #5aa5ef 0 0 32px 0px;
            box-shadow: #5aa5ef 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button.blueColor:active, .button.blueColor:target {
    background-color: #5aa5ef !important;
  }
  .button.blueColor:active.glow, .button.blueColor:target.glow {
    -webkit-box-shadow: #5aa5ef 0 0 32px 0px;
            box-shadow: #5aa5ef 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button.darkblueColor:hover:not(.outlined) {
    background-color: #002aff !important;
  }
  .button.darkblueColor:hover.outlined {
    background-color: #5aa5ef !important;
  }
  .button.darkblueColor:hover.glow {
    -webkit-box-shadow: #002aff 0 0 32px 0px;
            box-shadow: #002aff 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button.darkblueColor:active:not(.outlined), .button.darkblueColor:target:not(.outlined) {
    background-color: #002aff !important;
  }
  .button.darkblueColor:active.outlined, .button.darkblueColor:target.outlined {
    background-color: #5aa5ef !important;
  }
  .button.darkblueColor:active.glow, .button.darkblueColor:target.glow {
    -webkit-box-shadow: #002aff 0 0 32px 0px;
            box-shadow: #002aff 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button.grayColor:hover {
    background-color: #c6c6c6 !important;
  }
  .button.grayColor:hover.glow {
    -webkit-box-shadow: #c6c6c6 0 0 32px 0px;
            box-shadow: #c6c6c6 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button.grayColor:active, .button.grayColor:target {
    background-color: #c6c6c6 !important;
  }
  .button.grayColor:active.glow, .button.grayColor:target.glow {
    -webkit-box-shadow: #c6c6c6 0 0 32px 0px;
            box-shadow: #c6c6c6 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button.whiteColor:hover {
    background-color: #fff !important;
  }
  .button.whiteColor:hover.glow {
    -webkit-box-shadow: #fff 0 0 32px 0px;
            box-shadow: #fff 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button.whiteColor:active, .button.whiteColor:target {
    background-color: #fff !important;
  }
  .button.whiteColor:active.glow, .button.whiteColor:target.glow {
    -webkit-box-shadow: #fff 0 0 32px 0px;
            box-shadow: #fff 0 0 32px 0px;
  }
}

.button:not(.outlined) {
  border-color: transparent;
}

.button:not(.outlined).blackColor {
  background-color: #000;
}

.button:not(.outlined).blueColor, .button:not(.outlined).darkblueColor {
  background-color: #5aa5ef;
}

.button:not(.outlined).grayColor {
  background-color: #c6c6c6;
  color: #000 !important;
}

.button:not(.outlined).whiteColor {
  background-color: #fff;
}

.button.outlined {
  background-color: transparent;
}

.button.outlined.blackColor {
  border-color: #000;
  color: #000;
}

@media all and (hover: hover) {
  .button.outlined.blackColor:hover {
    color: #fff;
  }
}

@media all and (pointer: coarse) {
  .button.outlined.blackColor:active, .button.outlined.blackColor:target {
    color: #fff;
  }
}

.button.outlined.blueColor, .button.outlined.darkblueColor {
  border-color: #5aa5ef;
  color: #5aa5ef;
}

@media all and (hover: hover) {
  .button.outlined.blueColor:hover, .button.outlined.darkblueColor:hover {
    color: #fff;
  }
}

@media all and (pointer: coarse) {
  .button.outlined.blueColor:active, .button.outlined.blueColor:target, .button.outlined.darkblueColor:active, .button.outlined.darkblueColor:target {
    color: #fff;
  }
}

.button.outlined.grayColor {
  border-color: #c6c6c6;
  color: #000;
}

@media all and (hover: hover) {
  .button.outlined.grayColor:hover {
    color: #000;
  }
}

@media all and (pointer: coarse) {
  .button.outlined.grayColor:active, .button.outlined.grayColor:target {
    color: #000;
  }
}

.button.outlined.whiteColor {
  border-color: #fff;
  color: #fff !important;
}

@media all and (hover: hover) {
  .button.outlined.whiteColor:hover {
    color: #000 !important;
  }
}

@media all and (pointer: coarse) {
  .button.outlined.whiteColor:active, .button.outlined.whiteColor:target {
    color: #000 !important;
  }
}

.button.fullWidth {
  max-width: 100%;
  width: 100%;
}

.button.alignCenter {
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .button:not(.large) {
    border-radius: 5px;
    border-width: 2px;
    font-size: 16px;
    height: 40px;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .button:not(.large) {
    border-radius: 5.8333333333333px;
    border-width: 2.1875px;
    font-size: 16px;
    height: 43px;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .button:not(.large) {
    border-radius: 5.8333333333333px;
    border-width: 2.1875px;
    font-size: 18px;
    height: 46.50000000000001px;
    padding: 0 19.375000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .button:not(.large) {
    border-radius: 5.8333333333333px;
    border-width: 2.1875px;
    font-size: 18.75px;
    height: 46.50000000000001px;
    padding: 0 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .button:not(.large) {
    border-radius: 7.125px;
    border-width: 2.671875px;
    font-size: 22.265625px;
    height: 55.21875px;
    padding: 0 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .button:not(.large) {
    border-radius: 8px;
    border-width: 3px;
    font-size: 25px;
    height: 62px;
    padding: 0 31px;
  }
}

@media only screen and (max-width: 767px) {
  .button.large {
    border-radius: 6.25px;
    border-width: 1.6px;
    font-size: 14px;
    height: 50px;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .button.large {
    border-radius: 6.25px;
    border-width: 2.25px;
    font-size: 14.399999999999999px;
    height: 53.75px;
    padding: 0 19;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .button.large {
    border-radius: 6.25px;
    border-width: 2.25px;
    font-size: 17.5px;
    height: 53.75px;
    padding: 0 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .button.large {
    border-radius: 7.5px;
    border-width: 2.25px;
    font-size: 21px;
    height: 64.5px;
    padding: 0 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .button.large {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    font-size: 24.9375px;
    height: 63.59375px;
    padding: 0 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .button.large {
    border-radius: 10px;
    border-width: 3px;
    font-size: 28px;
    height: 86px;
    padding: 0 36px;
  }
}

.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .dashboard {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard {
    margin: 32px auto;
    max-width: 420px;
  }
}

@media only screen and (min-width: 900px) {
  .dashboard {
    height: 100%;
    margin: 0 auto;
    max-width: 1800px;
  }
}

.noContentWrap {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 500px;
  font-family: 'Montserrat'
}

.addContentButton {
  background-color: #5067FE;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  color: #fff;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.fileUploadButton {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container {
  margin-top: 40px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.noContentText {
  font-weight: 700;
  font-size: 20px;
  margin-top: 10px;
}

.noContentSubText {
  font-weight: 500;
  font-size: 17px;
  margin-top: 10px;
}

@media only screen and (max-width: 319px) {
  .fileUploadButton {
    top: 352px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .fileUploadButton {
    top: 357px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .fileUploadButton {
    top: 360px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .fileUploadButton {
    top: 336px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .fileUploadButton {
    top: 336px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .fileUploadButton {
    top: 345px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .fileUploadButton {
    top: 355px;
  }
}

@media only screen and (min-width: 1710px) {
  .fileUploadButton {
    top: 366px;
  }
}

.pageDivider {
  background-color: #707070;
  border: none;
  height: 1px;
  opacity: 0.18;
  width: 100%;
}

.subHeadingContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.filterText {
  color: #717171;
}

.subHeading {
  font-size: 20px;
  color: #0F1E5D;
  font-weight: 600;
}

.imageStyle {
  width: 150px;
  height: 120px;
  border-radius: 10px;
  margin-bottom: 10px;  
}

.contentWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 700px;
  margin-top: 40px;
}

.imgWrap {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;  
}

.fileName {
  font-size: 10px;
  text-align: center;
  font-family: 500;
  display: inline-block;
  width: 130px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.searchField {
  margin-top: 20px;
}
.title {
  text-align: center;
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: 'Montserrat' !important;

}
.content {
  text-align: center;
  color: #000;
  max-width: 300px;
  /* margin-left: 122px; */
  font-family: 'Montserrat' !important;
  font-weight: 500;

}
.actionContainer {
  /* margin : 4px 75px 18px 89px; */
  display: flex;
  justify-content: center;
  width: 100%;
}
.cancelButton{
  text-transform: none;
  font-family: 'Montserrat' !important;
  color: #000;
  background-color:#DEDEDE;
  font-size: 16px !important;
  font-weight: 500;
  /* margin-right: 89px; */

}
.continueButton{
  text-transform: none;
  color: #FF0000;
  background-color: #fff;
  font-family: 'Montserrat' !important;
  border-color: #707070 !important;
  font-size: 16px !important;
  font-weight: 500;
  margin-right: 14px;
}
.dialogContainer {
  border-radius: 15px;
}
.mainContent {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px !important;
  }
  .content {
    font-size: 15px;
  }
}
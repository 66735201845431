.logo {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 1440px) {
  .logo:not(.large) .logoImage {
    height: 48px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .logo:not(.large) .logoImage {
    height: 63px;
  }
}

@media only screen and (min-width: 1710px) {
  .logo:not(.large) .logoImage {
    height: 64px;
  }
}

@media only screen and (max-width: 767px) {
  .logo.large .logoImage {
    height: 66.305px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .logo.large .logoImage {
    height: 70.34375px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .logo.large .logoImage {
    height: 72.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .logo.large .logoImage {
    height: 78.74999999999999px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .logo.large .logoImage {
    height: 80.45312499999999px;
  }
}

@media only screen and (min-width: 1710px) {
  .logo.large .logoImage {
    height: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .logo.medium .logoImage {
    height: 63px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .logo.medium .logoImage {
    height: 63px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .logo.medium .logoImage {
    height: 63px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .logo.medium .logoImage {
    height: 63px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .logo.medium .logoImage {
    height: 63px;
  }
}

@media only screen and (min-width: 1710px) {
  .logo.medium .logoImage {
    height: 63px;
  }
}

.logoImage {
  position: relative;
}


@media only screen and (max-width: 1440px) {
  .logo.footerLogo .logoImage {
    height: 33px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .logo.footerLogo .logoImage {
    height: 35px;
  }
}

@media only screen and (min-width: 1710px) {
  .logo.footerLogo .logoImage {
    height: 37px;
  }
}

.sidebar {
  background-color: #fff;
  display: block;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

@media only screen and (min-width: 900px) {
  .sidebar {
    position: relative;
  }
}

@media only screen and (max-width: 899px) {
  .sidebar {
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 98;
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
  }
  .sidebar.noBottomBar {
    display: none !important;
  }
}

.noFixedSidebar {
  display: none !important;
}

@media only screen and (max-width: 899px) {
  .noBottomBar {
    display: none !important;
  }
}

.sidebarFill {
  opacity: 0;
  -webkit-box-ordinal-group: 101;
      -ms-flex-order: 100;
          order: 100;
  position: relative !important;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
}

@media only screen and (min-width: 900px) {
  .sidebarFill {
    display: none;
  }
}

.sidebarContent {
  width: 100%;
}

.sidebarContent::-webkit-scrollbar {
  width: 4px;
}

.sidebarContent::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.sidebarContent::-webkit-scrollbar-thumb {
  background: #6E6F6E; 
  border-radius: 25px;
}

.sidebarContent::-webkit-scrollbar-thumb:hover {
  background: #6E6F6E; 
}

@media only screen and (max-width: 899px) {
  .sidebarContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 49px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    position: relative;
  }
}

@media only screen and (min-width: 900px) {
  .sidebarContent {
    display: block;
    max-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-height);
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-top: max(5px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-top: max(6px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-top: max(6px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-top: max(7px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-top: max(8px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-top: max(9px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-top: max(9px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-top: max(10px, env(safe-area-inset-top));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-top: 5px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-top: 6px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-top: 6px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-top: 7px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-top: 8px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-top: 9px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-top: 9px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-top: 10px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-bottom: max(28px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-bottom: max(32px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-bottom: max(36px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-bottom: max(40px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-bottom: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-bottom: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-bottom: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-bottom: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-bottom: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-bottom: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-bottom: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-bottom: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sidebarContent {
    width: 160.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebarContent {
    width: 180.734375px;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebarContent {
    width: 200x;
  }
}

.link {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: 600;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  height: 50px;

}

@media only screen and (max-width: 899px) {
  .link {
    height: 100%;
    width: 49px;
  }
}

/* @media only screen and (min-width: 900px) and (max-width: 1440px) {
  .link {
    margin: 0 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .link {
    margin: 39.1875px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .link {
    margin: 44px 0;
  }
} */

.link:first-of-type {
  margin-top: 0 !important;
}

@media only screen and (max-width: 899px) {
  .currentLink {
    color: #5aa5ef;
    height: 100%;
    width: 49px;
  }
}

.currentLink::before {
  background-color: #5aa5ef;
  content: ' ';
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.currentLink {
  background-color: #D6EBF8;
}

@media only screen and (max-width: 899px) {
  .currentLink::before {
    display: none !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .currentLink::before {
    width: 3px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .currentLink::before {
    width: 3.5625px;
  }
}

@media only screen and (min-width: 1710px) {
  .currentLink::before {
    width: 4px;
  }
}

.linkIcon {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

@media only screen and (max-width: 899px) {
  .linkIcon {
    height: 28.9px;
    width: 28.9px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .linkIcon {
    height: 32.625px;
    margin-top: 12px;
    width: 32.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .linkIcon {
    height: 38.7421875px;
    margin-top: 14.25px;
    width: 38.7421875px;
  }
}

@media only screen and (min-width: 1710px) {
  .linkIcon {
    height: 43.5px;
    margin-top: 16px;
    width: 43.5px;
  }
}

.linkName {
  text-align: left;
  margin-left: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 899px) {
  .linkName {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .linkName {
    font-size: 12px;
    /* margin-bottom: 7.5px;
    margin-top: 6px; */
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .linkName {
    font-size: 14px;
    /* margin-bottom: 8.90625px;
    margin-top: 7.125px; */
  }
}

@media only screen and (min-width: 1710px) {
  .linkName {
    font-size: 16px;
    /* margin-bottom: 10px;
    margin-top: 8px; */
  }
}

.slidingSidebarContainer {
  position: relative;
}

.slidingSidebar {
  max-width: 360px;
  width: calc(100vw - 32px);
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebar {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebar {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebar {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebar {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebar {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebar {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebar {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebar {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebar {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebar {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebar {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebar {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebar {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebar {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebar {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebar {
      padding-bottom: 40px;
    }
  }
}

.logoContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 16px;
}

@media only screen and (max-width: 899px) {
  .logoContainer {
    background-color: #000;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-right: 40px;
    }
  }
}

.slidingSidebarLinksContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  padding-right: 0;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 40px;
    }
  }
}

.slidingSidebarLink {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  padding: 12px 0;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-left: max(12px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-left: max(16px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-left: max(20px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-left: max(28px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-left: max(32px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-left: max(36px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-left: max(40px, env(safe-area-inset-left)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-left: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-left: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-left: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-left: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-left: 32px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-left: 36px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-left: 40px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-right: max(32px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-right: max(36px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-right: max(40px, env(safe-area-inset-right)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-right: 32px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-right: 36px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-right: 40px !important;
    }
  }
}

@media all and (hover: hover) {
  .slidingSidebarLink:hover {
    background-color: #eee;
  }
}

@media all and (pointer: coarse) {
  .slidingSidebarLink:active, .slidingSidebarLink:target {
    background-color: #eee;
  }
}

.currentSlidingSidebarLink {
  color: #5aa5ef;
}

.slidingSidebarLinkIcon {
  height: 23px;
  width: 23px;
}

.slidingSidebarLinkName {
  font-size: 15px;
  margin-left: 21.5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slidingSidebarChildLink:not(.currentSlidingSidebarLink) {
  color: #777777;
}

.slidingSidebarChildLink .slidingSidebarLinkName {
  font-size: 15px;
  margin-left: 72px;
}

.chevronUp {
  margin-left: 12px;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

.noContentWrap {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 500px;
  font-family: 'Montserrat'
}

.addContentButton {
  background-color:#dbdbe0;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  color: #fff;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.addContentButton:hover {
  background-color: rgb(72, 139, 255);
}

.fileUploadButton {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container {
  margin-top: 120px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.noContentText {
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
  color: #afb2bf;
}

.noContentSubText {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  color: #afb2bf;
}
.noConetntSvg{
  height: 60px;
  width: 60px;
}

@media only screen and (max-width: 319px) {
  .fileUploadButton {
    top: 352px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .fileUploadButton {
    top: 357px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .fileUploadButton {
    top: 360px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .fileUploadButton {
    top: 336px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .fileUploadButton {
    top: 336px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .fileUploadButton {
    top: 345px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .fileUploadButton {
    top: 355px;
  }
}

@media only screen and (min-width: 1710px) {
  .fileUploadButton {
    top: 366px;
  }
}
